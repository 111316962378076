import { takeLatest } from 'redux-saga/effects';
import {
    GetLeaveTypesSaga,
    GetLeaveRequestSaga,
    AddLeaveRequestSaga,
    UpdateLeaveRequestSaga,
    DeleteLeaveRequestSaga,
    GetLeaveHistorySaga,
    LeaveStatusSaga,
    GetLeaveSummary,
    GetLeaveReport,
    GetLeaveOverallBalanceSaga,
    GetPendingLeaveApprovalSaga,
    GetUpcomingHolidaysSaga,
    GetHolidayCalendarSaga,
    AddHolidayCalendarSaga,
    UpdateHolidayCalendarSaga,
    DeleteHolidayCalendarSaga,
    GetLeaveLedger,
    GetLeaveApproval,
    AddLeaveTypesSaga,
    UpdateLeaveTypesSaga,
    DeleteLeaveTypesSaga,
    GetYearlyHolidayCalendar,
    DeleteYearlyHolidayCalendar,
    UpdateYearlyHolidayCalendar,
    AddYearlyHolidayCalendar,
    GetLeaveReason,
    AddLeaveReason,
    UpdateLeaveReason,
    DeleteLeaveReason,
    GetLeaveYearType,
    UpdateLeaveYearType,
    GetMyTimeConfigure,
    AddMyTimeConfigure,
    UpdateMyTimeConfigure,
    DeleteMyTimeConfigure,
    GetCurrentTime,
    GetMyTimeSheet,
    AddMyTimeSheet,
    UpdateMyTimeSheet,
    GetMyTimeSheetLeaveInfo,
    GetMyTimeSheetApproval,
    ApproveTimeSheet,
    ApproveTimeOff,
    GetFaceDataSaga
} from './LeaveSaga';
import * as ActionTypes from './ActionTypes';

export default function* LeaveWatcher() {
    yield takeLatest(ActionTypes.LEAVE_TYPE_GET_REQUEST, GetLeaveTypesSaga);
    yield takeLatest(ActionTypes.LEAVE_TYPE_ADD_REQUEST, AddLeaveTypesSaga);
    yield takeLatest(ActionTypes.LEAVE_TYPE_UPDATE_REQUEST, UpdateLeaveTypesSaga);
    yield takeLatest(ActionTypes.LEAVE_TYPE_DELETE_REQUEST, DeleteLeaveTypesSaga);
    yield takeLatest(ActionTypes.LEAVE_REQUEST_GET_REQUEST, GetLeaveRequestSaga);
    yield takeLatest(ActionTypes.LEAVE_REQUEST_ADD_REQUEST, AddLeaveRequestSaga);
    yield takeLatest(ActionTypes.LEAVE_REQUEST_UPDATE_REQUEST, UpdateLeaveRequestSaga);
    yield takeLatest(ActionTypes.LEAVE_REQUEST_DELETE_REQUEST, DeleteLeaveRequestSaga);
    yield takeLatest(ActionTypes.LEAVE_HISTORY_GET_REQUEST, GetLeaveHistorySaga);
    yield takeLatest(ActionTypes.LEAVE_STATUS_UPDATE_REQUEST, LeaveStatusSaga);
    yield takeLatest(ActionTypes.LEAVE_OVERALL_BALANCE_GET_REQUEST, GetLeaveOverallBalanceSaga);
    yield takeLatest(ActionTypes.PENDING_LEAVE_APPROVAL_GET_REQUEST, GetPendingLeaveApprovalSaga);
    yield takeLatest(ActionTypes.UPCOMING_HOLIDAYS_GET_REQUEST, GetUpcomingHolidaysSaga);
    yield takeLatest(ActionTypes.HOLIDAY_CALENDAR_GET_REQUEST, GetHolidayCalendarSaga);
    yield takeLatest(ActionTypes.HOLIDAY_CALENDAR_ADD_REQUEST, AddHolidayCalendarSaga);
    yield takeLatest(ActionTypes.HOLIDAY_CALENDAR_UPDATE_REQUEST, UpdateHolidayCalendarSaga);
    yield takeLatest(ActionTypes.HOLIDAY_CALENDAR_DELETE_REQUEST, DeleteHolidayCalendarSaga);
    yield takeLatest(ActionTypes.LEAVE_SUMMARY_GET_REQUEST, GetLeaveSummary);
    yield takeLatest(ActionTypes.LEAVE_REPORT_GET_REQUEST, GetLeaveReport);
    yield takeLatest(ActionTypes.LEAVE_LEDGER_GET_REQUEST, GetLeaveLedger);
    yield takeLatest(ActionTypes.LEAVE_APPROVAL_GET_REQUEST, GetLeaveApproval);
    yield takeLatest(ActionTypes.YEARLY_HOLIDAY_CALENDAR_GET_REQUEST, GetYearlyHolidayCalendar);
    yield takeLatest(ActionTypes.YEARLY_HOLIDAY_CALENDAR_ADD_REQUEST, AddYearlyHolidayCalendar);
    yield takeLatest(ActionTypes.YEARLY_HOLIDAY_CALENDAR_UPDATE_REQUEST, UpdateYearlyHolidayCalendar);
    yield takeLatest(ActionTypes.YEARLY_HOLIDAY_CALENDAR_DELETE_REQUEST, DeleteYearlyHolidayCalendar);
    yield takeLatest(ActionTypes.LEAVE_REASON_GET_REQUEST, GetLeaveReason);
    yield takeLatest(ActionTypes.LEAVE_REASON_ADD_REQUEST, AddLeaveReason);
    yield takeLatest(ActionTypes.LEAVE_REASON_UPDATE_REQUEST, UpdateLeaveReason);
    yield takeLatest(ActionTypes.LEAVE_REASON_DELETE_REQUEST, DeleteLeaveReason);
    yield takeLatest(ActionTypes.LEAVE_YEAR_TYPE_GET_REQUEST, GetLeaveYearType);
    yield takeLatest(ActionTypes.LEAVE_YEAR_TYPE_UPDATE_REQUEST, UpdateLeaveYearType);
    yield takeLatest(ActionTypes.MY_TIME_CONFIGURE_GET_REQUEST, GetMyTimeConfigure);
    yield takeLatest(ActionTypes.MY_TIME_CONFIGURE_ADD_REQUEST, AddMyTimeConfigure);
    yield takeLatest(ActionTypes.MY_TIME_CONFIGURE_UPDATE_REQUEST, UpdateMyTimeConfigure);
    yield takeLatest(ActionTypes.MY_TIME_CONFIGURE_DELETE_REQUEST, DeleteMyTimeConfigure);
    yield takeLatest(ActionTypes.CURRENT_TIME_GET_REQUEST, GetCurrentTime);
    yield takeLatest(ActionTypes.MY_TIME_SHEET_ADD_REQUEST, AddMyTimeSheet);
    yield takeLatest(ActionTypes.MY_TIME_SHEET_UPDATE_REQUEST, UpdateMyTimeSheet);
    yield takeLatest(ActionTypes.MY_TIME_SHEET_GET_REQUEST, GetMyTimeSheet);
    yield takeLatest(ActionTypes.MY_TIME_SHEET_LEAVE_INFO_GET_REQUEST, GetMyTimeSheetLeaveInfo);   
    yield takeLatest(ActionTypes.TIME_SHEET_APPROVAL_GET_REQUEST, GetMyTimeSheetApproval);
    yield takeLatest(ActionTypes.APPROVE_TIME_SHEET_REQUEST, ApproveTimeSheet);
    yield takeLatest(ActionTypes.APPROVE_TIME_OFF_REQUEST, ApproveTimeOff);
    yield takeLatest(ActionTypes.GET_FACE_DATA_REQUEST, GetFaceDataSaga);
}

