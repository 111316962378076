import { takeLatest } from "redux-saga/effects";
import {
  AddAtsUser,
  UpdateAtsUser,
  GetAtsCompany,
  AddAtsCompany,
  UpdateAtsCompany,
  DeleteAtsCompany,
  GetAtsGroupCompany,
  AddAtsGroupCompany,
  UpdateAtsGroupCompany,
  DeleteAtsGroupCompany,
  GetAtsConsultancy,
  AddAtsConsultancy,
  UpdateAtsConsultancy,
  DeleteAtsConsultancy,
  GetAtsCandidates,
  GetAtsQuickSearch,
  AddAtsCandidate,
  UpdateAtsCandidate,
  DeleteAtsCandidate,
  GetAtsJobPost,
  GetAtsJobOffer,
  GetAtsJobFilterAdmin,
  GetAtsJobFilterInternal,
  GetAtsJobFilterConsultancy,
  GetAtsJobFilterCompany,
  GetAtsJobFilterGroupOrg,
  AddAtsJobPost,
  UpdateAtsJobPost,
  GetAtsProfileScore,
  GetAtsInterviewDetails,
  AddAtsInterviewDetails,
  UpdateAtsInterviewDetails,
  DeleteAtsInterviewDetails,
  GetAtsUser,
  GetAtsUserInfo,
  GetAtsCandidatesByEmail,
  GetAtsInvoiceDetails,
  GetAtsInvoiceCandidateDetails,
  AddAtsInvoiceDetails,
  UpdateAtsInvoiceDetails,
  GetAtsGenerateInvoiceCandidateDetails,
  AddAtsWorkSpaceSwitch,
  AddAtsJobFilter,
  UploadResume,
  PythonApi,
  AtsTargetAchievementReport,
  AtsQualityofResumesReport,
  AtsDailyProductivityReport,
  AtsMonthlyReport,
  AtsReportRecruiter,
  AtsCandidateExport,
  AtsRevenueReport,
  AtsWorkLoadReport,
  AtsCandidateVerifiedReport,
  GetJobTemplate,
  AddJobTemplate,
  UpdateJobTemplate,
  DeleteJobTemplate,
  GetSkills,
  AddSkills,
  UpdateSkills,
  DeleteSkills,
  GetIndustryType,
  AddIndustryType,
  UpdateIndustryType,
  DeleteIndustryType,
  GetMyTask,
  UpdateMyTask,
  GetAssignedTask,
  AddAssignedTask,
  UpdateAssignedTask,
  DeleteAssignedTask,
  GetMeeplAdminIndustryType,
  GetMeeplAdminSkills,
  GetMeeplAdminJobTemplate,
  GetFormTemplate,
  AddFormTemplate,
  UpdateFormTemplate,
  DeleteFormTemplate,
  GetPreScreenTemplate,
  AddPreScreenTemplate,
  UpdatePreScreenTemplate,
  DeletePreScreenTemplate,
  GetOfferLetterTemplate,
  AddOfferLetterTemplate,
  UpdateOfferLetterTemplate,
  DeleteOfferLetterTemplate,
  GetAtsDesignationSaga,
  AddAtsDesignationSaga,
  UpdateAtsDesignationSaga,
  DeleteAtsDesignationSaga,
} from "./AtsSaga";
import * as ActionTypes from "./ActionTypes";

export default function* AtsWatcher() {
  yield takeLatest(ActionTypes.ATS_USER_GET_REQUEST, GetAtsUser);
  yield takeLatest(ActionTypes.ATS_USER_ADD_REQUEST, AddAtsUser);
  yield takeLatest(ActionTypes.ATS_USER_UPDATE_REQUEST, UpdateAtsUser);
  yield takeLatest(ActionTypes.ATS_COMPANY_GET_REQUEST, GetAtsCompany);
  yield takeLatest(ActionTypes.ATS_COMPANY_ADD_REQUEST, AddAtsCompany);
  yield takeLatest(ActionTypes.ATS_COMPANY_UPDATE_REQUEST, UpdateAtsCompany);
  yield takeLatest(ActionTypes.ATS_COMPANY_DELETE_REQUEST, DeleteAtsCompany);

  yield takeLatest(
    ActionTypes.ATS_GROUP_COMPANY_GET_REQUEST,
    GetAtsGroupCompany
  );
  yield takeLatest(
    ActionTypes.ATS_GROUP_COMPANY_ADD_REQUEST,
    AddAtsGroupCompany
  );
  yield takeLatest(
    ActionTypes.ATS_GROUP_COMPANY_UPDATE_REQUEST,
    UpdateAtsGroupCompany
  );
  yield takeLatest(
    ActionTypes.ATS_GROUP_COMPANY_DELETE_REQUEST,
    DeleteAtsGroupCompany
  );

  yield takeLatest(ActionTypes.ATS_CONSULTANCY_GET_REQUEST, GetAtsConsultancy);
  yield takeLatest(ActionTypes.ATS_CONSULTANCY_ADD_REQUEST, AddAtsConsultancy);
  yield takeLatest(
    ActionTypes.ATS_CONSULTANCY_UPDATE_REQUEST,
    UpdateAtsConsultancy
  );
  yield takeLatest(
    ActionTypes.ATS_CONSULTANCY_DELETE_REQUEST,
    DeleteAtsConsultancy
  );
  yield takeLatest(ActionTypes.ATS_CANDIDATE_GET_REQUEST, GetAtsCandidates);
  yield takeLatest(
    ActionTypes.ATS_CANDIDATE_DELETE_REQUEST,
    DeleteAtsCandidate
  );
  yield takeLatest(ActionTypes.ATS_QUICK_SEARCH_GET_REQUSET, GetAtsQuickSearch);
  yield takeLatest(ActionTypes.ATS_CANDIDATE_ADD_REQUEST, AddAtsCandidate);
  yield takeLatest(
    ActionTypes.ATS_CANDIDATE_UPDATE_REQUEST,
    UpdateAtsCandidate
  );
  yield takeLatest(ActionTypes.ATS_JOB_POST_GET_REQUEST, GetAtsJobPost);
  yield takeLatest(ActionTypes.ATS_JOB_OFFER_GET_REQUEST, GetAtsJobOffer);
  yield takeLatest(
    ActionTypes.ATS_JOB_FILTER_ADMIN_GET_REQUEST,
    GetAtsJobFilterAdmin
  );
  yield takeLatest(
    ActionTypes.ATS_JOB_FILTER_INTERNAL_GET_REQUEST,
    GetAtsJobFilterInternal
  );
  yield takeLatest(
    ActionTypes.ATS_JOB_FILTER_CONSULTANCY_GET_REQUEST,
    GetAtsJobFilterConsultancy
  );
  yield takeLatest(
    ActionTypes.ATS_JOB_FILTER_COMPANY_GET_REQUEST,
    GetAtsJobFilterCompany
  );
  yield takeLatest(
    ActionTypes.ATS_JOB_FILTER_GROUP_ORG_GET_REQUEST,
    GetAtsJobFilterGroupOrg
  );
  yield takeLatest(ActionTypes.ATS_JOB_POST_ADD_REQUEST, AddAtsJobPost);
  yield takeLatest(ActionTypes.ATS_JOB_POST_UPDATE_REQUEST, UpdateAtsJobPost);
  yield takeLatest(
    ActionTypes.ATS_PROFILE_SCORE_GET_REQUEST,
    GetAtsProfileScore
  );
  yield takeLatest(
    ActionTypes.ATS_INTERVIEW_DETAILS_GET_REQUEST,
    GetAtsInterviewDetails
  );
  yield takeLatest(
    ActionTypes.ATS_INTERVIEW_DETAILS_ADD_REQUEST,
    AddAtsInterviewDetails
  );
  yield takeLatest(
    ActionTypes.ATS_INTERVIEW_DETAILS_UPDATE_REQUEST,
    UpdateAtsInterviewDetails
  );
  yield takeLatest(
    ActionTypes.ATS_INTERVIEW_DETAILS_DELETE_REQUEST,
    DeleteAtsInterviewDetails
  );
  yield takeLatest(ActionTypes.ATS_USER_INFO_REQUEST, GetAtsUserInfo);
  yield takeLatest(
    ActionTypes.ATS_CANDIDATE_BY_EMAIL_GET_REQUEST,
    GetAtsCandidatesByEmail
  );
  yield takeLatest(ActionTypes.ATS_INVOICE_GET_REQUEST, GetAtsInvoiceDetails);
  yield takeLatest(ActionTypes.ATS_INVOICE_ADD_REQUEST, AddAtsInvoiceDetails);
  yield takeLatest(
    ActionTypes.ATS_INVOICE_UPDATE_REQUEST,
    UpdateAtsInvoiceDetails
  );
  yield takeLatest(
    ActionTypes.ATS_INVOICE_CANDIDATES_GET_REQUEST,
    GetAtsInvoiceCandidateDetails
  );
  yield takeLatest(
    ActionTypes.ATS_INVOICE_GENERATE_CANDIDATES_GET_REQUEST,
    GetAtsGenerateInvoiceCandidateDetails
  );
  yield takeLatest(
    ActionTypes.ATS_WORKSPACE_ADD_REQUEST,
    AddAtsWorkSpaceSwitch
  );
  yield takeLatest(ActionTypes.ATS_JOB_FILTER_ADD_REQUEST, AddAtsJobFilter);
  yield takeLatest(ActionTypes.ATS_RESUME_UPLOAD_REQUEST, UploadResume);
  yield takeLatest(ActionTypes.ATS_RESUME_PYTHON_API_REQUEST, PythonApi);
  yield takeLatest(
    ActionTypes.ATS_TARGET_ACHIEVEMENT_REPORT_GET_REQUEST,
    AtsTargetAchievementReport
  );
  yield takeLatest(
    ActionTypes.ATS_WORK_LOAD_REPORT_GET_REQUEST,
    AtsWorkLoadReport
  );
  yield takeLatest(
    ActionTypes.ATS_CANDIDATE_VERIFIED_REPORT_GET_REQUEST,
    AtsCandidateVerifiedReport
  );
  yield takeLatest(
    ActionTypes.ATS_QUALITY_OF_RESUME_REPORT_GET_REQUEST,
    AtsQualityofResumesReport
  );
  yield takeLatest(
    ActionTypes.ATS_DAILY_PRODUCTIVITY_REPORT_GET_REQUEST,
    AtsDailyProductivityReport
  );
  yield takeLatest(
    ActionTypes.ATS_MONTHLY_REPORT_GET_REQUEST,
    AtsMonthlyReport
  );
  yield takeLatest(
    ActionTypes.ATS_REVENUE_REPORT_GET_REQUEST,
    AtsRevenueReport
  );
  yield takeLatest(
    ActionTypes.ATS_REPORT_RECRUITER_GET_REQUEST,
    AtsReportRecruiter
  );
  yield takeLatest(
    ActionTypes.ATS_CANDIDATE_EXPORT_GET_REQUEST,
    AtsCandidateExport
  );
  yield takeLatest(ActionTypes.INDUSTRY_TYPE_GET_REQUEST, GetIndustryType);
  yield takeLatest(
    ActionTypes.MEEPL_ADMIN_INDUSTRY_TYPE_GET_REQUEST,
    GetMeeplAdminIndustryType
  );
  yield takeLatest(ActionTypes.INDUSTRY_TYPE_ADD_REQUEST, AddIndustryType);
  yield takeLatest(
    ActionTypes.INDUSTRY_TYPE_UPDATE_REQUEST,
    UpdateIndustryType
  );
  yield takeLatest(
    ActionTypes.INDUSTRY_TYPE_DELETE_REQUEST,
    DeleteIndustryType
  );
  yield takeLatest(
    ActionTypes.MEEPL_ADMIN_SKILLS_GET_REQUEST,
    GetMeeplAdminSkills
  );
  yield takeLatest(ActionTypes.SKILLS_GET_REQUEST, GetSkills);
  yield takeLatest(ActionTypes.SKILLS_ADD_REQUEST, AddSkills);
  yield takeLatest(ActionTypes.SKILLS_UPDATE_REQUEST, UpdateSkills);
  yield takeLatest(ActionTypes.SKILLS_DELETE_REQUEST, DeleteSkills);
  yield takeLatest(
    ActionTypes.MEEPL_ADMIN_JOB_TEMPLATE_GET_REQUEST,
    GetMeeplAdminJobTemplate
  );
  yield takeLatest(ActionTypes.JOB_TEMPLATE_GET_REQUEST, GetJobTemplate);
  yield takeLatest(ActionTypes.JOB_TEMPLATE_ADD_REQUEST, AddJobTemplate);
  yield takeLatest(ActionTypes.JOB_TEMPLATE_UPDATE_REQUEST, UpdateJobTemplate);
  yield takeLatest(ActionTypes.JOB_TEMPLATE_DELETE_REQUEST, DeleteJobTemplate);
  yield takeLatest(ActionTypes.MY_TASK_GET_REQUEST, GetMyTask);
  yield takeLatest(ActionTypes.MY_TASK_UPDATE_REQUEST, UpdateMyTask);
  yield takeLatest(ActionTypes.ASSIGNED_TASK_GET_REQUEST, GetAssignedTask);
  yield takeLatest(ActionTypes.ASSIGNED_TASK_ADD_REQUEST, AddAssignedTask);
  yield takeLatest(
    ActionTypes.ASSIGNED_TASK_UPDATE_REQUEST,
    UpdateAssignedTask
  );
  yield takeLatest(
    ActionTypes.ASSIGNED_TASK_DELETE_REQUEST,
    DeleteAssignedTask
  );
  yield takeLatest(ActionTypes.FORM_TEMPLATE_GET_REQUEST, GetFormTemplate);
  yield takeLatest(ActionTypes.FORM_TEMPLATE_ADD_REQUEST, AddFormTemplate);
  yield takeLatest(
    ActionTypes.FORM_TEMPLATE_UPDATE_REQUEST,
    UpdateFormTemplate
  );
  yield takeLatest(
    ActionTypes.FORM_TEMPLATE_DELETE_REQUEST,
    DeleteFormTemplate
  );
  yield takeLatest(
    ActionTypes.PRE_SCREEN_TEMPLATE_GET_REQUEST,
    GetPreScreenTemplate
  );
  yield takeLatest(
    ActionTypes.PRE_SCREEN_TEMPLATE_ADD_REQUEST,
    AddPreScreenTemplate
  );
  yield takeLatest(
    ActionTypes.PRE_SCREEN_TEMPLATE_UPDATE_REQUEST,
    UpdatePreScreenTemplate
  );
  yield takeLatest(
    ActionTypes.PRE_SCREEN_TEMPLATE_DELETE_REQUEST,
    DeletePreScreenTemplate
  );
  yield takeLatest(
    ActionTypes.OFFER_LETTER_TEMPLATE_GET_REQUEST,
    GetOfferLetterTemplate
  );
  yield takeLatest(
    ActionTypes.OFFER_LETTER_TEMPLATE_ADD_REQUEST,
    AddOfferLetterTemplate
  );
  yield takeLatest(
    ActionTypes.OFFER_LETTER_TEMPLATE_UPDATE_REQUEST,
    UpdateOfferLetterTemplate
  );
  yield takeLatest(
    ActionTypes.OFFER_LETTER_TEMPLATE_DELETE_REQUEST,
    DeleteOfferLetterTemplate
  );
  yield takeLatest(
    ActionTypes.ATS_DESIGNATION_GET_REQUEST,
    GetAtsDesignationSaga
  );
  yield takeLatest(
    ActionTypes.ATS_DESIGNATION_ADD_REQUEST,
    AddAtsDesignationSaga
  );
  yield takeLatest(
    ActionTypes.ATS_DESIGNATION_UPDATE_REQUEST,
    UpdateAtsDesignationSaga
  );
  yield takeLatest(
    ActionTypes.ATS_DESIGNATION_DELETE_REQUEST,
    DeleteAtsDesignationSaga
  );
}
