import { Box, FormControl, Grid } from "@mui/material";
import MTable from "material-table";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useState } from "react";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ConvertDateTime } from "../../../Base/helper/baseFunctions";
import { GetCandidatesVerifiedReportAction } from "../../../../../store/ATS/Report/Action";
const AtsCandidateVerifiedReports = () => {
    const dispatch = useDispatch();
    const date = new Date();
    const default_date = `${date?.getFullYear() + '-' + ("0" + (date?.getMonth() + 1)).slice(-2) + '-' + ("0" + date?.getDate()).slice(-2)}`;
    const [userData, setUserData] = useState({ load: false, values: [] })
    const [filters, setFilters] = useState({ recruiter_id: [], from_date: default_date, to_date: default_date });
    const { atsCandidateVerifiedData } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const { atsRecruiterData } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const parseAndSum = (field) => atsCandidateVerifiedData?.reduce((acc, curr) => acc + parseInt(curr[field] || '0', 10), 0);
    const sumcandidates_verified = parseAndSum('candidates_verified');
    const sumcandidates_added = parseAndSum('candidates_added');
    const hasNonZeroSum = sumcandidates_verified || sumcandidates_added;
    const dataWithSummary = hasNonZeroSum
        ? [
            ...atsCandidateVerifiedData,
            {
                recruiter_name: '',
                modified_date: 'Total',
                candidates_added: sumcandidates_added,
                candidates_verified: sumcandidates_verified,
            },
        ]
        : atsCandidateVerifiedData;
    useEffect(() => {
        let recruiter = []
        filters?.recruiter_id?.forEach(res => recruiter.push(res?.ats_users_id))
        dispatch(GetCandidatesVerifiedReportAction({
            from_date: filters?.from_date,
            to_date: filters?.to_date,
            recruiter_id: recruiter?.length > 0 ? `{${recruiter.join(',')}}` : null,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const handleFilter = (event, value) => {
        if (["recruiter_id"].includes(event)) {
            setFilters({ ...filters, [event]: value })
        }
        if (["from_date", "to_date"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value })
        }
    }
    return (
        <>
            <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} mb={1}>
                    <Grid key="recruiter_id" item xs={12} sm={5}>
                        <SelectableSearch
                            isOptionEqualToValue={(option, value) =>
                                +option.ats_users_id === +value.ats_users_id
                            }
                            label="User"
                            value={filters.recruiter_id}
                            loading={() => setUserData({ load: false, values: atsRecruiterData })}
                            fieldLabel="first_name"
                            required={false}
                            grouping={true}
                            multiple={true}
                            variant={"outlined"}
                            name="recruiter_id"
                            onChangeEvent={handleFilter}
                            data={userData}
                        />

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <CommonDatePicker
                                name="from_date"
                                label="From Date"
                                pickerType="date"
                                onKeyDown={(e) => e.preventDefault()}
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={filters.from_date}
                                maxDate={new Date()}
                                handleChange={(value) =>
                                    handleFilter({
                                        target: {
                                            name: "from_date",
                                            value: value
                                                ? `${value?.getFullYear() +
                                                "-" +
                                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                "-" +
                                                ("0" + value?.getDate()).slice(-2)
                                                }`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <CommonDatePicker
                                name="to_date"
                                label="To Date"
                                pickerType="date"
                                onKeyDown={(e) => e.preventDefault()}
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={filters.to_date}
                                maxDate={new Date()}
                                handleChange={(value) =>
                                    handleFilter({
                                        target: {
                                            name: "to_date",
                                            value: value
                                                ? `${value?.getFullYear() +
                                                "-" +
                                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                "-" +
                                                ("0" + value?.getDate()).slice(-2)
                                                }`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <MTable
                    columns={[
                        { title: "Recruiter", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "recruiter_name", editable: "never" },
                        { title: "Date", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "modified_date", editable: "never", render: rowData => { return rowData?.modified_date === 'Total'? 'Total': rowData?.modified_date? ConvertDateTime(rowData?.modified_date): " ";} },
                        { title: "Candidates Added", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "candidates_added", editable: "never" },
                        { title: "Candidates Verified", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "candidates_verified", editable: "never" },
                    ]}

                    data={dataWithSummary || []}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        rowStyle: rowData => ({
                            fontSize: "13px",
                            backgroundColor: rowData.modified_date === 'Total' ? 'rgb(254 255 187 / 48%)' : 'inherit',
                            fontWeight: rowData.modified_date === 'Total' ? 'bold' : 'normal',
                        }),
                        sorting: false,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        exportButton: atsCandidateVerifiedData?.length > 0 && {
                            csv: true,
                            pdf: true,
                        },
                        exportAllData: true,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        showTextRowsSelected: true,
                        selectionProps: rowData => ({
                            disabled: [null, '', 0, '0'].includes(rowData.commission_percentage),
                        }),
                        showSelectAllCheckbox: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                        columnsButton: true,
                        draggable: false
                    }}
                    title="Candidates Verified"
                />
            </Box>
        </>
    )
}

export default AtsCandidateVerifiedReports;