import * as ActionTypes from '../ActionTypes';

export const GetTargetAchievementAction = (params) => {
    return {
        type: ActionTypes.ATS_TARGET_ACHIEVEMENT_REPORT_GET_REQUEST,
        params
    }
}

export const GetQualityofResumesAction = (params) => {
    return {
        type: ActionTypes.ATS_QUALITY_OF_RESUME_REPORT_GET_REQUEST,
        params
    }
}

export const GetDailyProductivityAction = (params) => {
    return {
        type: ActionTypes.ATS_DAILY_PRODUCTIVITY_REPORT_GET_REQUEST,
        params
    }
}

export const GetMonthlyReportAction = (params) => {
    return {
        type: ActionTypes.ATS_MONTHLY_REPORT_GET_REQUEST,
        params
    }
}
export const GetRevenueAction = (params) => {
    return {
        type: ActionTypes.ATS_REVENUE_REPORT_GET_REQUEST,
        params
    }
}

export const GetReportRecruiterAction = (params) => {
    return {
        type: ActionTypes.ATS_REPORT_RECRUITER_GET_REQUEST,
        params
    }
}

export const GetWorkLoadAction = (params) => {
    return {
        type: ActionTypes.ATS_WORK_LOAD_REPORT_GET_REQUEST,
        params
    }
}

export const GetCandidatesVerifiedReportAction = (params) => {
    return {
        type: ActionTypes.ATS_CANDIDATE_VERIFIED_REPORT_GET_REQUEST,
        params
    }
}
